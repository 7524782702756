.app {
  text-align: center;
  background-color: #eeeeee;
  min-height: 100vh;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.app-container {
  display: grid;
  justify-self: start;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height: 100vh;
}

.app-header {
  display: flex;
  align-items: center;
  align-self: start;
  justify-content: left;
  padding-left: 25px;
  gap: 50px;
  box-shadow: 0px 1px 10px grey;
  width: 100%;
}

.app-body {
  width: 80%;
  justify-self: center;
  align-self: start;
  margin-top: 50px;
}

.header-title {
  font-weight: bold;
  color: #151975;
}

.content-footer {
  width: 100%;
  align-self: end;
}

/* .App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
} */
